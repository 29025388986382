import styled, { css } from 'styled-components';

export const FooterNavList = styled.ul`
  ${({ theme, ...props }) => {
    return css`
      display: flex;
    `;
  }};
`;

export const FooterNavListItem = styled.li<{ separator?: boolean }>`
  ${({ theme, separator }) => {
    return css`
      ${separator &&
      css`
        margin: 0 ${theme.spacing.base};
        border-right: 1px solid ${theme.color.white};
      `}
    `;
  }};
`;
