import styled, { css } from 'styled-components';

export const FooterElement = styled.div`
  ${({ theme }) => {
    return css`
      background: ${theme.color.secondary_500};
      color: ${theme.color.white};

      > div:first-child {
        border-top: 1px solid ${theme.color.neutral_600};
      }
    `;
  }};
`;

export const GridContainer = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${theme.spacing.base} 0;
      display: grid;
      grid-template-rows: repeat(3, 1fr);
      gap: ${theme.spacing.sm};
      align-items: start;
      justify-content: center;
      > {
        :nth-child(1) {
          order: 2;
        }
        :nth-child(2) {
          order: 1;
        }
        :nth-child(3) {
          order: 3;
        }
      }
      @media screen and (min-width: ${theme.breakpoint.sm}) {
        grid-template-columns: 3fr 2fr 1fr 1fr; // same as footer above
        grid-template-rows: 0;
        align-items: center;
        padding: ${theme.spacing.base} 0;
        > {
          :nth-child(1) {
            order: 1;
          }
          :nth-child(2) {
            order: 2;
          }
          :nth-child(3) {
            order: 3;
            grid-column-start: 4;
          }
        }
      }
    `;
  }};
`;
