import React, { MouseEvent, ReactNode } from 'react';
import { Text } from '@/components/typography';
import { Element } from './DevelopedBy.styles';
import { IS_PRODUCTION } from '@/utils/environment';

export interface DevelopedByProps {
  className?: string;
  children?: ReactNode;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void | undefined;
}

const DevelopedBy = ({ children, ...props }: DevelopedByProps) => {
  if (!IS_PRODUCTION) return null;
  return (
    <Element>
      <Text as="p" size="xs">
        <span>Developed by </span>
        <a href="https://adrianorosa.com" target="_blank">
          ADRIANOROSA
        </a>
      </Text>
    </Element>
  );
};

export default DevelopedBy;
