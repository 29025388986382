import styled, { css, keyframes } from 'styled-components';
import { ExchangeRatesProps } from '@/components/exchange/ExchangeRates';

const pulse = keyframes`
  0% {
    opacity: .6;
  }
  50% {
    opacity: 1%;
  }
  100% {
    opacity: .6;
  }
`;

export const Element = styled.div<ExchangeRatesProps>`
  ${({ theme, appearance }) => {
    return css`
      display: flex;
      align-items: center;
      gap: 0.275rem;
      color: ${theme.color.primary_400};
      line-height: 1.2;
      font-size: ${theme.typography.font_size.text_sm};
      text-transform: uppercase;
      small {
        font-size: ${theme.typography.font_size.text_xs};
      }

      > div {
        display: flex;
        align-items: center;
        gap: 0.275rem;

        + span {
          &:before {
            content: '|';
          }
        }
      }

      ${appearance === 'vertical' &&
      css`
        position: relative;
        flex-direction: column;
        > div {
          width: 100%;
          justify-content: space-between;

          + span {
            &:before {
              content: '';
              border-bottom: 1px solid ${theme.color.secondary_400};
              width: 100%;
              display: block;
              position: absolute;
              left: 0;
              right: 0;
            }
          }
        }
      `};
    `;
  }};
`;

export const PlaceholderText = styled.span<{ isLoading: boolean }>`
  ${({ isLoading }) => {
    return css`
      position: relative;
      border-radius: 1rem;
      filter: blur(0.2rem);
      &:before {
        overflow: hidden;
        content: '';
        position: absolute;
        inset: 0;
        background-color: grey;
        animation: ${pulse} 1.2s infinite;
      }
      ${isLoading === false &&
      css`
        filter: blur(0);

        &:before {
          animation: none;
          background-color: transparent;
        }
      `};
    `;
  }};
`;
