import styled, { css } from 'styled-components';
import { FooterStickyProps } from './FooterSticky';

export const FooterElement = styled.footer<FooterStickyProps>`
  ${({ theme }) => {
    return css`
      background: ${theme.color.secondary_500};
      color: ${theme.color.white};
      position: sticky;
      margin-bottom: 0;
      top: 100vh;
      width: 100%;
    `;
  }};
`;

export const FooterContentWrapper = styled.div`
  ${({ theme }) => {
    return css`
      padding: ${theme.spacing.base};
      display: flex;
      flex-direction: column;
      gap: ${theme.spacing.sm};
      align-items: flex-start;
      justify-content: center;

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        flex-direction: row;
        align-items: center;
      }

      @media screen and (min-width: ${theme.breakpoint.sm}) {
        padding: ${theme.spacing.base} 0;
      }
    `;
  }};
`;
