import React, { ReactNode } from 'react';
import { AnchorElement } from '@/components/footer/FooterNavlink/FooterNavlink.styles';

export interface FooterNavlinkProps {
  href: string;
  target?: '_blank';
  'aria-label'?: string;
  children?: ReactNode;
  className?: string;
}

const FooterNavlink = ({ href, children, ...props }: FooterNavlinkProps) => {
  return (
    <AnchorElement href={href} {...props}>
      {children}
    </AnchorElement>
  );
};

export default FooterNavlink;
