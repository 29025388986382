import React, { ReactNode } from 'react';
import { FooterContentWrapper, FooterElement } from './FooterSticky.styles';
import Container from '@/components/container/Container';
import DevelopedBy from '../DevelopedBy/DevelopedBy';
import Copyright from '@/components/footer/Copyright/Copyright';

export interface FooterStickyProps {
  className?: string;
  children?: ReactNode;
}

const FooterSticky = ({ children, ...props }: FooterStickyProps) => {
  return (
    <FooterElement {...props}>
      <Container>
        <FooterContentWrapper>
          <Copyright />
          <DevelopedBy />
        </FooterContentWrapper>
      </Container>
    </FooterElement>
  );
};

export default FooterSticky;
