import React from 'react';
import { FooterNavList, FooterNavListItem } from './FooterBottomNav.styles';
import FooterNavlink from '@/components/footer/FooterNavlink/FooterNavlink';
import { Text } from '@/components/typography';

export interface FooterBottomNavProps {
  bottomNavMenu: {
    sitemap: string;
    privacyPolicy: string;
  };
}

const FooterBottomNav = ({ bottomNavMenu, ...props }: FooterBottomNavProps) => {
  return (
    <FooterNavList>
      <FooterNavListItem>
        <FooterNavlink href={bottomNavMenu.sitemap}>
          <Text size="xs">Site Map</Text>
        </FooterNavlink>
      </FooterNavListItem>
      <FooterNavListItem separator />
      <FooterNavListItem>
        <FooterNavlink href={bottomNavMenu.privacyPolicy}>
          <Text size="xs">Política de Privacidade</Text>
        </FooterNavlink>
      </FooterNavListItem>
    </FooterNavList>
  );
};

export default FooterBottomNav;
