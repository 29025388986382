import styled, { css } from 'styled-components';
import Link, { LinkProps } from 'next/link';

// FIXME: Solve the overload TS issue here
// @ts-ignore
export const AnchorElement = styled(Link)<LinkProps>`
  ${({ theme, ...props }) => {
    return css`
      font-size: ${theme.typography.font_size.text_sm};
      &,
      &:hover {
        color: ${theme.color.secondary_200};
      }
      &:hover {
        text-decoration: underline;
      }
    `;
  }};
`;
