import React from 'react';
import { FooterElement, GridContainer } from './FooterBottomLine.styles';
import Container from '@/components/container/Container';
import DevelopedBy from '@/components/footer/DevelopedBy/DevelopedBy';
import Copyright from '@/components/footer/Copyright/Copyright';
import FooterBottomNav, { FooterBottomNavProps } from '@/components/footer/FooterBottomNav/FooterBottomNav';

export interface FooterBottomLineProps extends FooterBottomNavProps {}

const FooterBottomLine = ({ bottomNavMenu, ...props }: FooterBottomLineProps) => {
  return (
    <FooterElement>
      <Container>
        <GridContainer>
          <Copyright />
          <FooterBottomNav bottomNavMenu={bottomNavMenu} />
          <DevelopedBy />
        </GridContainer>
      </Container>
    </FooterElement>
  );
};

export default FooterBottomLine;
