import React, { ReactNode } from 'react';
import { Title } from '@/components/typography';
import { TitleProps } from '@/components/typography/Title/Title';

export interface HeadingGroupProps {
  className?: string;
  children?: ReactNode;
}

const HeadingGroup = ({ children, ...props }: HeadingGroupProps & Omit<TitleProps, 'as'>) => {
  return (
    <Title as="h4" size="base" weight="semi_bold" textCase="uppercase" color="primary_400" margin="base">
      {children}
    </Title>
  );
};

export default HeadingGroup;
