import React from 'react';
import { Text } from '@/components/typography';

export interface CopyrightProps {}

const Copyright = ({ ...props }: CopyrightProps) => {
  return (
    <Text as="p" size="xs">
      Copyright © {new Date().getFullYear()}. Kangaroo Tours Operadora Turística. Todos os Direitos Reservados.
    </Text>
  );
};

export default Copyright;
