import dayjs from 'dayjs';
import { useMemo } from 'react';
import useSWR, { Fetcher } from 'swr';
import { API_URL } from '@/helpers/browser';

require('dayjs/locale/pt-br');
dayjs.locale('pt-br');

type ExchangeType = {
  usd: string;
  eur: string;
  updated_at: string | null;
};

type DataResponse = {
  data: ExchangeType;
};

const fetcher: Fetcher<DataResponse, any> = (path: string) => {
  return fetch(path, {
    method: 'GET',
    // credentials: 'include',
    // mode: 'cors',
  }).then((res) => res.json());
};

const DATE_FORMAT = 'DD MMM YYYY';

export default function useExchangeRate() {
  const { data, isLoading, error } = useSWR(API_URL.daily_exchange_rate, fetcher, {
    refreshInterval: 100000,
  });

  const values: ExchangeType = useMemo(() => {
    if (data?.data) {
      return {
        ...data.data,
        updated_at: data.data.updated_at ? dayjs(data.data.updated_at).format(DATE_FORMAT) : null,
      };
    }

    return {
      usd: '0,00',
      eur: '0,00',
      updated_at: dayjs().format(DATE_FORMAT),
    };
  }, [data?.data]);

  return {
    values,
    isLoading,
    error,
  };
}
