import React from 'react';
import { FooterElement, GridContainer } from '@/components/footer/Footer/Footer.styles';
import { Container } from '@/components/container';
import HeadingGroup from '@/components/footer/HeadingGroup/HeadingGroup';
import FooterNavlink from '@/components/footer/FooterNavlink/FooterNavlink';
import { Text } from '@/components/typography';
import { FooterBottomLine } from '@/components/footer';
import { urlPacotes } from '@/config/url';
import FormSubscriber from '@/features/forms/FormSubscriber/FormSubscriber';
import ExchangeRates from '@/components/exchange/ExchangeRates';
import { useAppContext } from '@/context/AppContext';

export interface FooterProps {
  className?: string;
}

const Footer = ({ ...props }: FooterProps) => {
  const { navigation } = useAppContext();
  const { footer } = navigation || {};

  if (footer === undefined) return null;

  return (
    <FooterElement>
      <Container>
        <GridContainer>
          <div>
            <HeadingGroup>viagens nacionais e internacionais</HeadingGroup>
            <ul>
              {footer.pacotesMenu
                .filter((item) => item.continent !== 'Brasil')
                .map((item) => (
                  <li key={item.slug}>
                    <FooterNavlink href={urlPacotes(item.slug)}>{item.title}</FooterNavlink>
                  </li>
                ))}
            </ul>
            <FooterNavlink href="/pacotes">Todos os destinos {`>`}</FooterNavlink>
          </div>

          <div>
            <HeadingGroup>para sua viagem</HeadingGroup>
            <ul>
              {footer.paraSuaViagemMenu.map((item) => (
                <li key={item.id}>
                  <FooterNavlink href={item.href} aria-label={item.title}>
                    {item.title}
                  </FooterNavlink>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <HeadingGroup>newsletter</HeadingGroup>
            <Text size="xs" margin="sm">
              Assine a nossa newsletter para receber ofertas exclusivas e as últimas notícias sobre nossos produtos e
              serviços.
            </Text>
            <FormSubscriber />
          </div>

          <div>
            <HeadingGroup>{footer.usefulInfo.title}</HeadingGroup>
            <ul>
              {footer.usefulInfo.contents.map((item) => (
                <li key={`usefull-${item.slug}`}>
                  <FooterNavlink href={item.permalink} target={item.slug === 'blog' ? '_blank' : undefined}>
                    {item.title}
                  </FooterNavlink>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <HeadingGroup>sobre a kangaroo</HeadingGroup>
            <ul>
              {footer.quemSomosMenu.map((item) => (
                <li key={item.id}>
                  <FooterNavlink href={item.href} aria-label={item.title}>
                    {item.title}
                  </FooterNavlink>
                </li>
              ))}
            </ul>
          </div>

          <div>
            <HeadingGroup>câmbio do dia</HeadingGroup>
            <ExchangeRates appearance="vertical" />
          </div>

          <div>
            <HeadingGroup>redes sociais</HeadingGroup>
            <Text size="sm" weight="bold" margin="base">
              Siga a Kangaroo
            </Text>
            <ul>
              {footer.socialMediaMenu.map((item) => (
                <li key={item.id}>
                  <FooterNavlink href={item.href} target="_blank" className={item.id}>
                    <span>{item.title}</span>
                  </FooterNavlink>
                </li>
              ))}
            </ul>
          </div>
        </GridContainer>
        <FooterBottomLine bottomNavMenu={footer.bottomNavMenu} />
      </Container>
    </FooterElement>
  );
};

export default Footer;
